.image-container-project {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	align-items: flex-start;
	margin-top: 100px;
}

.image-container-project img {
	flex-basis: calc(100% / 6);
	flex-grow: 4;
	max-width: 25vw;
	height: auto;
	padding: 1rem;
	transition: all 0.2s ease-in-out; /* add transition effect */
}

.image-container-project img:hover {
	transform: scale(1.1); /* grow image size on hover */
}

.project-desc {
	font-family: 'Libre Baskerville', serif;
}

.project-header {
	font-family: 'Libre Baskerville', serif;
}
