.video-container {
	position: fixed;
	z-index: 1;
	overflow: hidden;
	margin-top: -160px;
}

.intro-video {
	object-fit: cover;
	border: none;
}

video {
	transition: opacity 2s ease-out;
}

.fade-out {
	z-index: -1;
	opacity: 0;
	transition-delay: 1s;
}

.remove-video {
	z-index: 9999;
}