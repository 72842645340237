@tailwind base;
@tailwind components;
@tailwind utilities;

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
  }

  body {
	font-family: system-ui;
/* 	background-image: linear-gradient(45deg, hsl(0, 0%, 100%) 0%, hsl(42, 86%, 89%) 100%); */
	background-color: rgb(255, 248, 232);
  }

  svg {
	display: block;
  }
